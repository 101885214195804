import { useEffect, useState } from "react";
import Button from "../extra/Button";
import Input from "../extra/Input";
import Logo from "../../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { loginAdmin } from "../store/admin/admin.action";
import { connect, useSelector } from "react-redux";
import Logo1 from "../../assets/images/logo1.png";
import Wall1 from "../../assets/images/w1.jpg";
const Login = (props) => {
  let navigate = useNavigate();

  const isAuth = useSelector((state) => state.admin.isAuth);

  useEffect(() => {
    isAuth && navigate("/admin");
  }, [isAuth]);

  const [email, setEmail] = useState("admin@admin.com");
  const [password, setPassword] = useState("123456");
  const [types, setTypes] = useState("password");

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = () => {
    if (!email || !password) {
      let error = {};
      if (!email) error.email = "Email Is Required !";
      if (!password) error.password = "password is required !";
      return setError({ ...error });
    } else {
      let login = {
        email,
        password,
      };

      props.loginAdmin(login);
    }
  };
  const hideShow = () => {
    types === "password" ? setTypes("text") : setTypes("password");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="mainLoginPage position-relative">
        {/* <img src={Wall1} className="loginImage"/> */}
        <div className="loginDiv">
          <div className="logoBar d-flex align-items-center ">
            <div className="loginLogo boxCenter me-3 pt-1 pe-1">
              <img
                src={Logo1}
                alt=""
                width={"100px"}
                style={{
                  borderRadius: "50%",
                  marginLeft: "4px",
                  boxShadow: "0 0 10px ",
                }}
              />
            </div>
            <div className="logoText text-center">
              <h3 class="">Welcome To</h3>
              <h2 className="fw-bold   mb-0">XChat</h2>
            </div>
          </div>
          <div className="loginPage pt-3">
            <div className="loginInput mb-5">
              <div className="prime-input ">
                <label htmlFor="loginEmail">Email</label>
                <div className="d-flex">
                  <Button
                    type="button"
                    btnIcon={`fa-regular fa-envelope`}
                    newClass={`themeBtn text-center fs-6 bgDark  text-white btn-primary p-0 loginIcons`}
                  />
                  <input
                    type={email}
                    className="form-input loginFields"
                    id={email}
                    onKeyPress={handleKeyPress}
                    value={email}
                    // onWheel={(e) => type === "number" && e.target.blur()}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          email: `Email Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          email: "",
                        });
                      }
                    }}
                  />
                </div>
                {error.email && <span className="text-red">{error.email}</span>}
              </div>

              <div className="prime-input ">
                <label htmlFor="loginEmail">Password</label>
                <div className="d-flex">
                  <Button
                    type="button"
                    btnIcon={`fa-solid fa-lock`}
                    newClass={`themeBtn text-center fs-6 bgDark p-0  text-white loginIcons`}
                  />
                  <input
                    type={types}
                    className="loginFields form-input "
                    id={password}
                    value={password}
                    onKeyPress={handleKeyPress}
                    // onWheel={(e) => type === "number" && e.target.blur()}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          password: `Password Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          password: "",
                        });
                      }
                    }}
                  />
                  <div className="passHideShow" onClick={hideShow}>
                    {types === "password" ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash"></i>
                    )}
                  </div>
                </div>
                {error.password && (
                  <span className="text-red">{error.password}</span>
                )}
              </div>
            </div>
            {/* <div className="forgetPassword themeFont primeBlackColor my-4">
              Forgot password?
            </div> */}
            <div className="loginButton mt-4">
              <Button
                newClass={`text-white`}
                btnColor={`blackPrime`}
                btnName={`Sign In`}
                onClick={handleSubmit}
                style={{ borderRadius: "6px", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { loginAdmin })(Login);
