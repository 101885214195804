import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getHostFeedBack,
  getUserFeedBack,
  deleteFeedBack,
  feedBackSolved,
} from "../../store/feedback/feedback.action";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import Title from "../../extra/Title";
import Pagination from "../../extra/Pagination";
import Searching from "../../extra/Searching";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { warning } from "../../util/Alert";
import Analytics from "../../extra/Analytics";
import FeedBackDialog from "./FeedBackDialog";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import SolvedFeedBackDialog from "./SolvedFeedBackDialog";
import Male from "../../../assets/images/boy.jpg";
const FeedBackPending = (props) => {
  const { userFeedBack, hostFeedBack, total } = useSelector(
    (state) => state.feedBack
  );
  const { dialogue, dialogueData, dialogueType } = useSelector(
    (state) => state.dialogue
  );

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("ALL");
  const [type, setType] = useState(1);

  const navigate = useNavigate();
  const handleFeedBack = (id) => {
    // props.feedBackSolved(id);
  };
  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 20));
    setCurrentPage(1);
  };

  useEffect(() => {
    type === 1 &&
      dispatch(
        getUserFeedBack(
          search,
          "pending",
          currentPage,
          rowsPerPage,
          startDate,
          endDate
        )
      );
    type === 2 &&
      dispatch(
        getHostFeedBack(
          search,
          "pending",
          currentPage,
          rowsPerPage,
          startDate,
          endDate
        )
      );
  }, [type, startDate, endDate, currentPage, rowsPerPage, search]);

  useEffect(() => {
    type === 1 && setData(userFeedBack);
    type === 2 && setData(hostFeedBack);
  }, [userFeedBack, hostFeedBack]);

  const handleInfo = (id) => {
    type === 2 &&
      navigate("/admin/hostInfo", {
        state: id,
      });
    type === 1 &&
      navigate("/admin/userInfo", {
        state: id,
      });
  };

  const mapData = [
    {
      Header: "Id",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Author",
      body: "profilePic",
      Cell: ({ row }) => (
        <div>
          <div className="d-flex px-2 py-1 justify-content-center">
            <div>
              <img
                src={row?.image ? row?.image : Male}
                alt=""
                draggable="false"
                style={{ borderRadius: "50%" }}
                height={60}
                width={60}
              />
            </div>
            <div className="d-flex flex-column justify-content-center text-start ms-3">
              <b className="mb-0 text-sm text-capitalize ">{row.name}</b>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: "Contact",
      body: "contact",
      // Cell: ({ row }) => <span>{row.contact}</span>,
    },
    {
      Header: "Type",
      body: "type",
      Cell: ({ row }) => (
        <span>
          {row.type == 1
            ? "Recharge"
            : row.type == 2
            ? "Gifting"
            : row.type == 3
            ? "Stream"
            : row.type == 4
            ? "Events"
            : row.type == 5
            ? "Suggestions"
            : "Others"}
        </span>
      ),
    },
    {
      Header: "Date",
      body: "date",
      Cell: ({ row }) => <span>{dayjs(row?.date).format("DD MMM YYYY")}</span>,
    },
    {
      Header: "isSolved",
      body: "isSolved",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isSolved}
          // onClick={() => handleFeedBack(row._id)}
          onClick={() =>
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "SolvedFeedBack" },
            })
          }
        />
      ),
    },
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn text-white fs-6 border`}
          btnColor={`bg-primary`}
          btnIcon={`fa-solid fa-info`}
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { type: "feedBack", data: row },
            });
          }}
          style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  const handleDelete = (feedBackId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteFeedBack(feedBackId);
          console.log(feedBackId);
        }
      })
      .catch((err) => console.log(err));
  };
  // const start = (start) => {
  //   setStartDate(start);
  // };

  // const end = (endDate) => {
  //   setEndDate(endDate);
  // };
  const handleUserFeedBack = () => {
    setType(1);
  };

  const handleHostFeedBack = () => {
    setType(2);
  };
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  return (
    <>
      <div className="mainUserTable">
        {dialogue && dialogueType === "feedBack" && <FeedBackDialog />}
        {dialogue && dialogueType === "SolvedFeedBack" && (
          <SolvedFeedBackDialog />
        )}
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Pending Feedback`} />
            </div>
          </div>
          <div className="d-flex row pe-4 mt-4 px-3">
            {type == 1 ? (
              <div
                className="d-flex fs-4 justify-content-start fw-bold align-items-center col-6 ps-4"
                style={{ color: "rgb(56 157 218)" }}
              >
                User Feedback
              </div>
            ) : (
              <div className="d-flex fs-4 primeColor justify-content-start fw-bold align-items-center col-6 ps-4">
                Host Feedback
              </div>
            )}

            <div className="d-flex justify-content-end col-6">
              <Button
                btnName={`User`}
                newClass={`text-white ms-2`}
                onClick={handleUserFeedBack}
                style={{
                  width: "100px",
                  borderRadius: "5px",
                  background: "rgb(56 157 218)",
                }}
              />
              <Button
                btnName={`Host`}
                newClass={`bgInfo text-white ms-2 btnPrime`}
                onClick={handleHostFeedBack}
                style={{ width: "100px", borderRadius: "5px" }}
              />
            </div>
          </div>
          <div className="primeHeader row boxBetween p-0 px-3">
            <div className="col-3 ms-2 ">
              <Analytics
                analyticsStartDate={startDate}
                analyticsStartEnd={endDate}
                analyticsStartDateSet={setStartDate}
                analyticsStartEndSet={setEndDate}
              />
            </div>

            <div className="d-flex justify-content-end col-6 mt-auto">
              <Searching
                type={"server"}
                data={type === 1 ? userFeedBack : hostFeedBack}
                setData={setData}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                column={mapData}
                placeholder={"Searching Feedback..."}
              />
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
              />

              <Pagination
                component="div"
                count={userFeedBack?.length}
                serverPage={currentPage}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={total}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};
export default connect(null, {
  getHostFeedBack,
  getUserFeedBack,
  deleteFeedBack,
  feedBackSolved,
})(FeedBackPending);
