import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Dashboard from "../Table/dashboard/Dashboard";
import User from "../Table/user/User";
import Setting from "../Table/setting/Setting";
import $ from "jquery";
import { useEffect } from "react";
import PrivateRoute from "../util/PrivateRoute";
import "../../assets/js/custom";
import CoinPlan from "../Table/Plan/CoinPlan";
import PurchaseHistory from "../Table/Plan/PurchaseHistory";
import Agency from "../Table/Agency/Agency";
import FakeHost from "../Table/Host/FakeHost/FakeHost";
import Host from "../Table/Host/RealHost/Host";
import Category from "../Table/GiftCategory/Category";
import Gift from "../Table/Gift/Gift";
import FlashCoin from "../Table/FlashCoin/FlashCoin";
import HostInfo from "../Table/Host/RealHost/HostInfo";
import UserInfo from "../Table/user/UserInfo";
import AgencyWiseHost from "../Table/Agency/AgencyWiseHost";
import FeedBackSolved from "../Table/feedBack/FeedBackSolved";
import FeedBackPending from "../Table/feedBack/FeedBackPending";
import AdminProfile from "./AdminProfile";
import CustomScript from "../../assets/js/CustomScript";
import VipPlan from "../Table/Plan/VipPlan/VipPlan";
import Commission from "../Table/commission/Commission";
import AllSettlement from "../Table/Settlement/AllSettlement";
import SettlementSolved from "../Table/Settlement/SettlementSolved";
import SettlementPending from "../Table/Settlement/SettlementPending";
import AgencySettleMent from "../Table/Settlement/AgencySettleMent";
import AgencyWeekSettlement from "../Table/Settlement/AgencyWeekSettlement";
import Notification from "./Notification";
import HostHistory from "../Table/Host/RealHost/History/HostHistory";
import Report from "../Table/report/Report";
import AppWiseSetting from "../Table/setting/AppWiseSetting";
import Topic from "../Table/topic/Topic";
import VipBanner from "../Table/vipBanner/VipBanner";
import FakeStory from "../Table/fakeStory/FakeStory";
import FlashVip from "../Table/FlashVip/FlashVip";
import AgencyComission from "../Table/Agency/AgencyComission";
import FakeHostDialog from "../Table/Host/FakeHost/FakeHostDialog";

const Admin = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == ""
    ) {
      // window.location.href = "/admin/dashboard";

      // // window.history.pushState(null, null, "/admin/dashboar");

      navigate("/admin/dashboard");
    }
  }, [location.pathname]);

  return (
    <>
      {/* <CustomScript /> */}
      <div className="mainContainer d-flex w-100">
        <div className="containerLeft">
          <Sidebar />
        </div>

        <div className="containerRight w-100 ">
          <Navbar />

          <div className="mainAdmin me-4">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/userTable" element={<User />} />
              <Route path="/settingPage" element={<Setting />} />
              <Route path="/multiAppSetting" element={<AppWiseSetting />} />
              <Route path="/plan" element={<CoinPlan />} />
              <Route path="/planHistory" element={<PurchaseHistory />} />
              <Route path="/agencyTable" element={<Agency />} />
              <Route path="/realHost" element={<Host />} />
              <Route path="/agencyCommission" element={<AgencyComission />} />
              <Route path="/fakeHost" element={<FakeHost />} />
              <Route path="/addFakeHost" element={<FakeHostDialog />} />
              <Route path="/category" element={<Category />} />
              <Route path="/gifts" element={<Gift />} />
              <Route path="/giftCategory/gifts" element={<Gift />} />
              <Route path="/flashCoinTable" element={<FlashCoin />} />
              <Route path="/flashVipTable" element={<FlashVip />} />
              <Route path="/hostInfo" element={<HostInfo />} />
              <Route path="/userInfo" element={<UserInfo />} />

              <Route path="/agencyWiseHost" element={<AgencyWiseHost />} />
              <Route path="/pendingFeedback" element={<FeedBackPending />} />
              <Route path="/solvedFeedback" element={<FeedBackSolved />} />
              <Route path="/infoAdmin" element={<AdminProfile />} />
              <Route path="/vipBannerTable" element={<VipBanner />} />
              <Route path="/vipPlan" element={<VipPlan />} />

              <Route path="/allSettlement" element={<AllSettlement />} />
              <Route path="/settlementSolved" element={<SettlementSolved />} />
              <Route
                path="/settlementPending"
                element={<SettlementPending />}
              />
              <Route path="/commission" element={<Commission />} />

              <Route path="/agencySettlement" element={<AgencySettleMent />} />
              <Route
                path="/agencyWeekSettlement"
                element={<AgencyWeekSettlement />}
              />
              <Route path="/notification" element={<Notification />} />
              <Route path="/host/hostHistory" element={<HostHistory />} />
              <Route path="/report" element={<Report />} />
              <Route path="/topicModule" element={<Topic />} />

              {/* story */}

              <Route path="/story" element={<FakeStory />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
