import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import "../../assets/js/custom";
import Navigator from "../extra/Navigator";
import { warning } from "../util/Alert";
import { useDispatch } from "react-redux";
import { LOGOUT_ADMIN } from "../store/admin/admin.type";
import { CLOSE_DIALOGUE } from "../store/dialogue/dialogue.type";
import $ from "jquery";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as SettingIcon } from "../../assets/icons/Setting.svg";
import { ReactComponent as AgencyIcon } from "../../assets/icons/AgencyIcon.svg";
import { ReactComponent as HostIcon } from "../../assets/icons/HostIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/User.svg";
import { ReactComponent as Plan } from "../../assets/icons/Plan.svg";
import { ReactComponent as FlashCoin } from "../../assets/icons/FlashCoin.svg";
import { ReactComponent as Gift } from "../../assets/icons/Gift.svg";
import { ReactComponent as Commission } from "../../assets/icons/commission.svg";
import { ReactComponent as Profile } from "../../assets/icons/Plan.svg";
import { ReactComponent as LogOut } from "../../assets/icons/Logout.svg";
import { ReactComponent as Feedback } from "../../assets/icons/Feedback.svg";
import { ReactComponent as Report } from "../../assets/icons/Report.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/DownArrow.svg";
import { ReactComponent as Settlement } from "../../assets/icons/settlement.svg";
import { ReactComponent as Topic } from "../../assets/icons/Topic.svg";
import { ReactComponent as BannerIcon } from "../../assets/icons/bannerIcon.svg";
import { ReactComponent as Story } from "../../assets/icons/Story.svg";
import { useEffect, useState } from "react";
import CustomScript from "../../assets/js/CustomScript";

const Sidebar = () => {
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    const data = warning();
    data
      .then((logout) => {
        if (logout) {
          dispatch({ type: LOGOUT_ADMIN });
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleGift = () => {
    localStorage.setItem("GiftClick", true);
    if (window.innerWidth <= 992) {
      $(".sideBar").toggleClass("mobSidebar webSidebar");
    }
  };

  // const handelRemoveItem = () => {
  //   // localStorage.removeItem(JSON.parse("dialogueData"))

  //   localStorage.removeItem(JSON.parse("dialogueData"));
  // };

  const handelRemoveItem = () => {
    localStorage.removeItem("dialogueData");
    dispatch({ type: CLOSE_DIALOGUE });
    if (window.innerWidth <= 992) {
      $(".sideBar").toggleClass("mobSidebar webSidebar");
    }
  };

  // eslint-disable-next-line no-sparse-arrays
  const navBarArray = [
    {
      name: "Dashboard",
      path: "/admin/dashboard",
      navSVG: <DashboardIcon />,
      onClick: handelRemoveItem,
    },
    {
      name: "Agency",
      path: "/admin/agencyTable",
      navSVG: <AgencyIcon />,
      onClick: handelRemoveItem,
    },
    {
      name: "Host",
      // onClick: handelRemoveItem,
      navSVG: <HostIcon />,
      subMenu: [
        {
          subName: "Real Host",
          subPath: "/admin/realHost",
          onClick: handelRemoveItem,
        },
        {
          subName: "Fake Host",
          subPath: "/admin/fakeHost",
          onClick: handelRemoveItem,
        },
      ],
    },
    {
      name: "User",
      path: "/admin/userTable",
      navSVG: <UserIcon />,
      onClick: handelRemoveItem,
    },
    {
      name: "Story",
      path: "/admin/story",
      navSVG: <Story />,
      onClick: handelRemoveItem,
    },
    {
      name: "Vip Banner",
      path: "/admin/vipBannerTable",
      navSVG: <BannerIcon />,
      onClick: handelRemoveItem,
    },
    {
      name: "Plan",
      navSVG: <Plan />,
      subMenu: [
        {
          subName: "Coin Plan",
          subPath: "/admin/plan",
          onClick: handelRemoveItem,
        },
        {
          subName: "Vip Plan",
          subPath: "/admin/vipPlan",
          onClick: handelRemoveItem,
        },
        {
          subName: "Flash Coin",
          subPath: "/admin/flashCoinTable",
          onClick: handelRemoveItem,
        },
        {
          subName: "Flash VIP",
          subPath: "/admin/flashVipTable",
          onClick: handelRemoveItem,
        },
        {
          subName: "Purchased History",
          subPath: "/admin/planHistory",
          onClick: handelRemoveItem,
        },
      ],
    },
    // {
    //   name: "Flash Coin",
    //   path: "/admin/flashCoinTable",
    //   navSVG: <FlashCoin />,
    //   onClick: handelRemoveItem,
    // },
    // {
    //   name: "Flash VIP",
    //   path: "/admin/flashVipTable",
    //   navSVG: <FlashCoin />,
    //   onClick: handelRemoveItem,
    // },
    {
      name: "Gift",
      navSVG: <Gift />,
      subMenu: [
        {
          subName: "Category",
          subPath: "/admin/category",
          onClick: handleGift,
        },
        {
          subName: "Gift",
          subPath: "/admin/gifts",
          onClick: handleGift,
        },
      ],
    },
    {
      name: "Settlement",
      navSVG: <Settlement />,
      subMenu: [
        {
          subName: "All Settlement",
          subPath: "/admin/allSettlement",
          onClick: handelRemoveItem,
        },
        {
          subName: "Solved",
          subPath: "/admin/settlementSolved",
          onClick: handelRemoveItem,
        },
        {
          subName: "Pending",
          subPath: "/admin/settlementPending",
          onClick: handelRemoveItem,
        },
      ],
    },
    // {
    //   name: "Commission",
    //   path: "/admin/commission",
    //   navSVG: <Commission />,
    //   onClick: handelRemoveItem,

    //   onClick: handelRemoveItem,
    // },
    {
      name: "Feedback",
      navSVG: <Feedback />,
      subMenu: [
        {
          subName: "Solved",
          subPath: "/admin/solvedFeedback",
          onClick: handelRemoveItem,
        },
        {
          subName: "Pending",
          subPath: "/admin/pendingFeedback",
          onClick: handelRemoveItem,
        },
      ],
    },
    {
      name: "Topic",
      navSVG: <Topic />,
      path: "/admin/topicModule",
      onClick: handelRemoveItem,
    },
    {
      name: "Report",
      navSVG: <Report />,
      path: "/admin/report",
      onClick: handelRemoveItem,
    },
  ];

  const supportArray = [
    {
      name: "Profile",
      navSVG: <Profile />,
      path: "/admin/infoAdmin",
      onClick: handelRemoveItem,
    },
    {
      name: "Setting",
      navSVG: <SettingIcon />,
      subMenu: [
        {
          subName: "Main Setting",
          subPath: "/admin/settingPage",
          onClick: handelRemoveItem,
        },
        {
          subName: "All App Setting",
          subPath: "/admin/multiAppSetting",
          onClick: handelRemoveItem,
        },
      ],
    },

    {
      name: "Logout",
      // navIcon: "fa-solid fa-right-from-bracket",
      navSVG: <LogOut />,
      onClick: handleLogout,
    },
  ];

  const [totalPage, setTotalPage] = useState(9);

  return (
    <>
      <Script totalPage={totalPage} />
      <div className="mainSidebar">
        <div className="sideBar webSidebar">
          <Link to="/admin/dashboard">
            <div className="sideBarLogo boxCenter">
              <img
                src={Logo}
                alt=""
                width={"40px"}
                className="me-2"
                style={{ borderRadius: "20px" }}
              />
              <span className="fs-3 fw-bold text-white">X Chat</span>
            </div>
          </Link>
          <hr className="mx-3 my-0" />

          {/* ======= Navigation ======= */}
          <div className="navigation">
            <p className="menuName" style={{ fontSize: "14px" }}>
              MENU
            </p>
            {/* <nav style={{ backgroundColor: "#f0f0f0" }}> */}
            <nav
              style={{
                background: "#191919",
                borderRadius: "10px",
                // height: "495px",
                // overflow: "auto",
              }}
            >
              {/* About */}
              {navBarArray?.length > 0 && (
                <>
                  {/*    {(totalPage > 0 ? navBarArray.slice(0, totalPage) : navBarArray).map( */}
                  {navBarArray.map((res) => {
                    return (
                      <>
                        <Navigator
                          name={res?.name}
                          path={res?.path}
                          path2={res?.path2}
                          navIcon={res?.navIcon}
                          navIconImg={res?.navIconImg}
                          navSVG={res?.navSVG}
                          onClick={res?.onClick && res?.onClick}
                        >
                          {res?.subMenu &&
                            res?.subMenu?.map((subMenu) => {
                              return (
                                <Navigator
                                  subName={subMenu.subName}
                                  subPath={subMenu.subPath}
                                  subPath2={subMenu.subPath2}
                                  onClick={subMenu.onClick}
                                />
                              );
                            })}
                        </Navigator>
                      </>
                    );
                  })}
                </>
              )}
            </nav>
            {/* <div className="boxCenter mt-2">
                <DownArrow  onClick={()=> setTotalPage(navBarArray.length)} className={`text-center mx-auto cursor ${totalPage === navBarArray.length && "d-none"}`} style={{transition: "0.5s"}}/>
              </div> */}
            <p className="mt-4" style={{ fontSize: "14px" }}>
              {" "}
              SUPPORT
            </p>
            <div
              style={{
                background: "#191919",
                borderRadius: "10px",
              }}
            >
              {supportArray.map((res) => {
                return (
                  <>
                    <Navigator
                      name={res?.name}
                      path={res?.path}
                      navIcon={res?.navIcon}
                      navSVG={res?.navSVG}
                      onClick={res?.onClick && res?.onClick}
                    >
                      {res?.subMenu &&
                        res?.subMenu?.map((subMenu) => {
                          return (
                            <Navigator
                              subName={subMenu.subName}
                              subPath={subMenu.subPath}
                              subPath2={subMenu.subPath2}
                              onClick={subMenu.onClick}
                            />
                          );
                        })}
                    </Navigator>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

// export const Script = (props) => {
//   useEffect(() => {
//     const handleClick = (event) => {
//       const target = event.currentTarget;
//       $(".subMenu").not($(target).next(".subMenu")).slideUp();
//       $(target).next(".subMenu").slideToggle();
//       $(target).children("i").toggleClass("rotate90");
//     };

//     const handleToggle = () => {
//       $(".mainNavbar").toggleClass("mobNav webNav");
//       $(".sideBar").toggleClass("mobSidebar webSidebar");
//       $(".mainAdmin").toggleClass("mobAdmin");
//       $(".fa-angle-right").toggleClass("rotated toggleIcon");

//     };
//     $(".subMenu").hide();
//     $(".mainMenu > li > a").on("click", handleClick);
//     $(".navToggle").on("click", handleToggle);

//     return () => {
//       $(".mainMenu > li > a").off("click", handleClick);
//       $(".navToggle").off("click", handleToggle);
//     };
//   }, [props.totalPage]);

//   return null;
// };

export const Script = (props) => {
  useEffect(() => {
    const handleClick = (event) => {
      const target = $(event.currentTarget);
      const submenu = target.next(".subMenu");

      $(".subMenu").not(submenu).slideUp();
      submenu.slideToggle();

      // Toggle rotation class on the clicked icon
      target.children("i").toggleClass("rotate90");

      // Remove rotation class from other icons
      $(".mainMenu > li > a > i")
        .not(target.children("i"))
        .removeClass("rotate90");
    };

    const handleToggle = () => {
      $(".mainNavbar").toggleClass("mobNav webNav");
      $(".sideBar").toggleClass("mobSidebar webSidebar");
      $(".mainAdmin").toggleClass("mobAdmin");
      $(".fa-angle-right").toggleClass("rotated toggleIcon");
    };

    $(".subMenu").hide();
    $(".mainMenu > li > a").on("click", handleClick);
    $(".navToggle").on("click", handleToggle);

    return () => {
      $(".mainMenu > li > a").off("click", handleClick);
      $(".navToggle").off("click", handleToggle);
    };
  }, [props.totalPage]);

  return null;
};
