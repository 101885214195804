import React, { useMemo } from "react";
import Table from "../../../extra/Table";
import Button from "../../../extra/Button";
import Title from "../../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import dayjs from "dayjs";
import Pagination from "../../../extra/Pagination";
import {
  getHost,
  approvedDisableSwitch,
  blockUnblockSwitch,
} from "../../../store/host/host.action";
import { useNavigate } from "react-router-dom";
import Searching from "../../../extra/Searching";

import { OPEN_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import HostDialog from "./HostDialog";
import HostInfo from "./HostInfo";
import HostHistory from "./History/HostHistory";
import Male from "../../../../assets/images/malePlace.png"

const Host = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("ALL");
  const [sort, setSort] = useState("createdAt");
  const [type, setType] = useState(0);
  const dispatch = useDispatch();

  const { host, total } = useSelector((state) => state.host);

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    
    dispatch(
      getHost(
        search === "" ? "ALL" : search,
        currentPage,
        rowsPerPage,
        sort,
        type
      )
    );
  }, [search, currentPage, rowsPerPage, sort, type]);

  useEffect(() => {
    setData(host);
  }, [host]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  // // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  const handleClickBlock = (userDetails) => {
    props.blockUnblockSwitch(
      userDetails,
      userDetails?.isBlock === true ? false : true
    );
  };

  const handelSettlement = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const mapData = [
    {
      Header: "NO",
      width: "20px",
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Author",
      body: "profilePic",
      Cell: ({ row }) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleProfile(row, "hostInfo")}
        >
          <div className="d-flex px-2 py-1">
            <div>
              <img
                src={row?.profilePic ? row?.profilePic : Male}
                alt=""
                loading="eager"
                draggable="false"
                style={{ borderRadius: "25px" }}
                height={50}
                width={50}
              />
            </div>
            <div className="d-flex flex-column justify-content-center text-start ms-3">
              <b className="mb-0 text-sm text-capitalize ">{row.name}</b>
              <div>
                <div className="d-flex">
                  <PlaceIcon
                    style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
                  />
                  <p
                    className="text-xs text-secondary mb-0  text-capitalize"
                    style={{ fontSize: "12px" }}
                  >
                    {row?.countryId?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: "Unique Id",
      body: "uniqueId",
      Cell: ({ row }) => (
        <span className="text-success">
          {row.uniqueId ? row.uniqueId : "-"}
        </span>
      ),
    },
    {
      Header: "Agency Name",
      body: "agencyId",
      Cell: ({ row }) => <span>{row?.agencyId?.name}</span>,
    },
    {
      Header: "Gender",
      body: "gender",
      Cell: ({ row }) => <span>{row.gender ? row.gender : "Female"}</span>,
    },
    {
      Header: "Coin",
      body: "coin",
      sorting: { type: "server" },
      Cell: ({ row }) => <span>{row?.coin}</span>,
    },
    // {
    //   Header: "Call Rate",
    //   body: "callCharge",
    //   sorting: { type: "server" },
    //   // Cell: ({ row }) => <span>{row?.callCharge}</span>,
    // },
    {
      Header: "Last Settlement Coin",
      body: "lastSettlementCoin",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="text-danger">
          {row.lastSettlementCoin ? row.lastSettlementCoin : "0"}
        </span>
      ),
    },
    {
      Header: "Received Coin",
      body: "receiveCoin",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="text-success">{row.receiveCoin ? row.receiveCoin : 0}</span>
      ),
    },
    {
      Header: "Received Gift",
      body: "receiveGift",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span className="text-primary">{row.receiveGift}</span>
      ),
    },
    {
      Header: "App Version",
      body: "appVersion",
      Cell: ({ row }) => <span>{row.appVersion}</span>,
    },
    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row.createdAt).format("DD MMM YYYY")}</span>
      ),
    },
    {
      Header: "Last Alive",
      body: "lastLogin",
      Cell: ({ row }) => (
        <span className="text-nowrap">
          {dayjs(row.lastLogin).format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      Header: "Status",
      body: "",
      Cell: ({ row }) =>
        row?.isOnline === true &&
        row?.isLive === true &&
        row?.isBusy === true ? (
          <div
            className="bg-primary py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Live
          </div>
        ) : row?.isOnline === true && row?.isBusy === true ? (
          <div
            className="bgPink py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Busy
          </div>
        ) : row?.isOnline === true ? (
          <div
            className="bg-success py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Online
          </div>
        ) : (
          <div
            className="bg-danger py-1 px-2 d-inline-block text-white"
            style={{
              fontSize: "11px",
              borderRadius: "5px",
            }}
          >
            Offline
          </div>
        ),
    },
    {
      Header: "Block Host",
      body: "isDisable",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isBlock}
          onClick={() => handleClickBlock(row)}
        />
      ),
    },

    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn textPink fs-5`}
          btnColor={``}
          btnIcon={`far fa-edit`}
          // onClick={() => handleEdit(row)}
          style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "Host" },
            });
          }}
        />
      ),
    },
    {
      Header: "Info",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn text-white fs-6 border`}
          btnColor={`bg-primary`}
          btnIcon={`fa-solid fa-info`}
          onClick={() => handleProfile(row, "hostInfo")}
          style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
        />
      ),
    },
    {
      Header: "History",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn text-success fs-5`}
          btnColor={`bg-white`}
          btnIcon={`fa-solid fa-history`}
          style={{ borderRadius: "5px", margin: "auto", width: "40px" }}
          onClick={() => handelSettlement(row, "hostHistory")}
        />
      ),
    },

    // add more columns as needed
  ];

  // const handleFilterData = (filteredData) => {
  //   if (typeof filteredData === "string") {
  //     setSearch(filteredData);
  //   } else {
  //     setData(filteredData);
  //   }
  // };

  const handleFilterData = useMemo(() => {
    return (filteredData) => {
      if (typeof filteredData === "string") {
        setSearch(filteredData);
      } else {
        setData(filteredData);
      }
    };
  }, []);

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
    localStorage.setItem("realHostInfo", JSON.stringify(dialogueData_));
  };
  const handleChildValue = (value) => {
    setType(type === -1 ? 1 : -1);
    setSort(value);
  };

  return (
    <>
      <div className="mainUserTable  position-relative">
        {dialogue && dialogueType == "hostHistory" && (
          <div className="mainHostInfo">
            <HostHistory />
          </div>
        )}

        {dialogue && dialogueType == "hostInfo" && (
          <div className="mainHostInfo">
            <HostInfo />
          </div>
        )}

        <div
          className="userTable "
          style={{
            display: `${
              dialogueType === "hostInfo"
                ? "none"
                : dialogueType === "hostHistory"
                ? "none"
                : "block"
            }`,
          }}
        >
          <div className="userHeader primeHeader">
            <Title name={`Host`} />
          </div>
          <div className="primeHeader mt-4 row">
            {/* <i className="fas fa-plus fa-md"></i> */}
            <div className="col-md-6 mt-2">
              <Button
                newClass={` border bgsuccess text-white`}
                btnColor={`btnthemePrime`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`New Host`}
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "Host" },
                  });
                }}
                style={{ borderRadius: "7px" }}
              />
              {dialogue && dialogueType === "Host" && <HostDialog />}
            </div>
            <div className="d-flex justify-content-end col-md-6">
              <Searching
                type={"server"}
                data={host}
                setData={setData}
                setSearchData={setSearch}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                column={mapData}
                placeholder={"Searching Host..."}
              />
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>

            <Pagination
              component="div"
              count={host?.length}
              type={"server"}
              serverPerPage={rowsPerPage}
              totalData={total}
              serverPage={currentPage}
              onPageChange={handleChangePage}
              setCurrentPage={setCurrentPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getHost,
  approvedDisableSwitch,
  blockUnblockSwitch,
})(Host);
