import * as ActionType from "./fakeHost.type";
// const storedData = localStorage.getItem("fakeHostData");

const initialState = {
  // fakeHost: storedData ? JSON.parse(storedData) : [],
  fakeHost:[],
  total: null,
  excelFile: [],
  // excelFileSend:{}
};

export const fakeHostReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_FAKE_HOST:
      return {
        ...state,
        fakeHost: action.payload.data,
        total: action.payload.total,
      };
    case ActionType.GET_FAKE_HOST_FOR_STORY:
      return {
        ...state,
        fakeHost: action.payload.data,
       
      };
    case ActionType.SEND_EXCEL:
      // const data = state.fakeHost.concat(action.payload);
      const data =[...action.payload , ...state.fakeHost ]
      return {
        ...state,
        fakeHost: data,
        total:state.total+[...action.payload]?.length
      };
      case ActionType.CREATE_FAKE_HOST:
        const data1 = [...state.fakeHost];
        data1.unshift(action.payload);
        return {
          ...state,
          fakeHost: data1,
        };
      

        case ActionType.UPDATE_FAKE_HOST:
          return {
            ...state,
            fakeHost: state.fakeHost.map((updateHost) =>
              updateHost._id === action.payload.id
                ? action.payload.data
                : updateHost
            ),
          };

    case ActionType.BLOCKED_HOST:
      return {
        ...state,
        fakeHost: state.fakeHost.map((fakeHostBlock) => {
          if (fakeHostBlock._id === action.payload.id)
            return action.payload.data;
          else return fakeHostBlock;
        }),
        // hostProfile: action.payload.data,
      };
    case ActionType.APPROVED_HOST:
      return {
        ...state,
        fakeHost: state.fakeHost.map((fakeHostApproved) => {
          if (fakeHostApproved._id === action.payload.id)
            return action.payload.data;
          else return fakeHostApproved;
        }),
        // hostProfile: action.payload.data,
      };
    case ActionType.DELETE_HOST_IMG:
      
      return {
        ...state,
        fakeHost: state.fakeHost.filter(
          (fakeHost) => fakeHost._id !== action.payload
        ),
      };
    case ActionType.DELETE_FAKE_HOST:
      debugger
      
      return {
        ...state,
        fakeHost: state.fakeHost.filter(
          (fakeHost) => fakeHost?._id !== action?.payload
        ),
      };
    // case ActionType.SEND_EXCEL:
    //   return {
    //     ...state,
    //     excelFileSend:action.payload
    //   }

    default:
      return state;
  }
};
