import { width } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import dayjs from "dayjs";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import {
  deleteBanner,
  getBanner,
  handleVIPSwitch,
} from "../../store/vipBanner/banner.action";
import { addCommissionPlan } from "../../store/commission/commission.action";
import { warning } from "../../util/Alert";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { baseURL } from "../../util/config";
import VipBannerDialogue from "./VipBannerDialogue";

const VipBanner = (props) => {
  const { banner } = useSelector((state) => state.banner);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBanner());
  }, [dispatch]);

  useEffect(() => {
    setData(banner);
  }, [banner]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteBanner(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleOpenImage = (url) => {
    window.open(url, "_blank");
  };

  const handleIsVIPSwitch = (userDetails) => {
    props.handleVIPSwitch(
      userDetails,
      userDetails?.isVIP === true ? false : true
    );
  };
  const mapData = [
    {
      Header: "No",
      width: "20px",
      Cell: ({ index }) => <span>{index + 1}</span>,
    },
    {
      Header: "Banner Image",
      body: "image",
      Cell: ({ row }) => (
        <div>
          <img
            src={baseURL + row?.image}
            alt=""
            draggable="false"
            style={{
              borderRadius: "5px",
              boxShadow: "rgba(105, 103, 103, 0) 0px 5px 15px 0px",
            }}
            onClick={() => handleOpenImage(baseURL + row?.image)}
            height={80}
            width={120}
          />
        </div>
      ),
    },
    {
      Header: "Is Vip",
      body: "isVIP",
      Cell: ({ row }) => (
        <ToggleSwitch
          value={row?.isVIP}
          onClick={() => handleIsVIPSwitch(row?._id)}
        />
      ),
    },

    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mainCommission ">
        <div className="comission primeHeader">
          <div className="comissionHeader ">
            <div className="row align-items-center">
              <div className="col-12">
                <Title name={`Vip Banner`} />
              </div>
              <div className="col-6"></div>
              <div className="col-6 ms-auto mt-3">
                <Button
                  newClass={`border text-white userBtn`}
                  btnColor={`bg-primary`}
                  btnName={`New`}
                  btnIcon={`fas fa-plus fa-md`}
                  onClick={() => {
                    dispatch({
                      type: OPEN_DIALOGUE,
                      payload: { type: "vipBanner" },
                    });
                  }}
                  style={{
                    borderRadius: "5px",
                    marginLeft: "auto",
                    width: "120px",
                    float: "right",
                  }}
                />

                {dialogue && dialogueType === "vipBanner" && (
                  <VipBannerDialogue />
                )}
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3 m-0">
              <Table
                data={data}
                mapData={mapData}
                PerPage={rowsPerPage}
                Page={page}
                type={"client"}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={banner?.length}
                serverPage={page}
                type={"client"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={banner?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getBanner,
  deleteBanner,
  handleVIPSwitch,
})(VipBanner);
