import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../util/Alert";
import Button from "../../extra/Button";
import { createNewBanner } from "../../store/vipBanner/banner.action";

import { baseURL } from "../../util/config";

const VipBannerDialogue = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const [mongoId, setMongoId] = useState(0);

  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState({
    image: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setError({
      image: "",
    });

    setImage([]);
  }, []);

  useEffect(() => {
    setMongoId(dialogueData?._id);

    setImagePath(dialogueData?.image ? baseURL + dialogueData?.image : "");
  }, [dialogueData]);

  const handleImage = (e) => {
    // BOTH ARE WORKING STEP : 1
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }

    // BOTH ARE WORKING STEP : 2

    setError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    if (!image || !imagePath) {
      let error = {};

      if (!image || !imagePath) error.image = "Image is required!";

      return setError({ ...error });
    } else {
      //   if (!hasPermission) return permissionError();
    
      const formData = new FormData();
      
      formData.append("image", image);
    
        props.createNewBanner(formData);
     
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  return (
    <div className="mainDialogue fade-in">
      <div
        className="Dialogue"
        style={{ width: "500px", overflow: "auto", maxHeight: "100vh" }}
      >
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">Vip Banner Dialog</div>
          <div
            className="closeBtn "
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i className="fa-solid fa-xmark ms-2"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label={`Agent Image`}
                id={`image`}
                type={`file`}
                onChange={(e) => handleImage(e)}
                errorMessage={error.image && error.image}
              />
              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath}
                    alt="banner"
                    draggable="false"
                    width={100}
                    height={100}
                    className="m-0 cursor rounded"
                    onClick={() => showImg(imagePath)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="dialogueFooter">
            <div className="dialogueBtn">
              <Button
                btnName={`Submit`}
                btnColor={`btnBlackPrime text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                newClass={`me-2`}
                onClick={handleSubmit}
              />
              <Button
                btnName={`Close`}
                btnColor={`bg-danger text-white`}
                style={{ borderRadius: "5px", width: "80px" }}
                onClick={() => {
                  dispatch({ type: CLOSE_DIALOGUE });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {createNewBanner})(
  VipBannerDialogue
);
