import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import { permissionError } from "../../util/Alert";
import Button from "../../extra/Button";
import {
  createNewCategory,
  editCategory,
} from "../../store/giftCategory/action";
import { baseURL } from "../../util/config";

const CategoryDialog = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  console.log("dialogueData :>> ", dialogueData);

  const [mongoId, setMongoId] = useState(0);
  const [name, setName] = useState("");

  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [error, setError] = useState({
    name: "",
    image: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setMongoId(dialogueData?._id);
    setName(dialogueData?.name);

    setImagePath(dialogueData?.image ? baseURL + dialogueData?.image : "");
  }, [dialogueData]);

  const handleImage = (e) => {
    // BOTH ARE WORKING STEP : 1
    // if (e.target.files[0] == "") {
    //     setError({
    //       ...error,
    //       image: "Image require",
    //     });

    // } else {
    //      setError({
    //        ...error,
    //        image: "",
    //      })
    // }

    // BOTH ARE WORKING STEP : 2

    setError((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    if (!name || !image || !imagePath) {
      let error = {};
      if (!name) error.name = "Name is Required !";

      if (!image || !imagePath) error.image = "Image is Required!";

      return setError({ ...error });
    } else {
      //   if (!hasPermission) return permissionError();
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);

      if (mongoId) {
        props.editCategory(formData, mongoId);
      } else {
        props.createNewCategory(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };
  return (
    <div className="mainDialogue fade-in">
      <div className="Dialogue" style={{width: "400px",overflow:'auto',maxHeight:'100vh'}}>
        <div className="dialogueHeader">
          <div className="headerTitle fw-bold">GIft Category</div>
          <div
            className="closeBtn "
            onClick={() => {
              dispatch({ type: CLOSE_DIALOGUE });
            }}
          >
            <i className="fa-solid fa-xmark ms-2"></i>
          </div>
        </div>
        <div className="dialogueMain">
          <div className="row">
            <div className="col-12">
              <Input
                label={`Category Name`}
                id={`name`}
                type={`text`}
                value={name}
                errorMessage={error.name && error.name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (!e.target.value) {
                    return setError({
                      ...error,
                      name: `name Is Required`,
                    });
                  } else {
                    return setError({
                      ...error,
                      name: "",
                    });
                  }
                }}
              />
            </div>

            <div className="col-12">
              <Input
                label={`Image`}
                id={`image`}
                type={`file`}
                onChange={(e) => handleImage(e)}
                errorMessage={error.image && error.image}
              />
              {imagePath && (
                <div className="image-start">
                  <img
                    src={imagePath}
                    alt="banner"
                    draggable="false"
                    width={100}
                    className="m-0"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dialogueFooter">
          <div className="dialogueBtn">
            <Button
              btnName={`Submit`}
              btnColor={`btnBlackPrime text-white`}
              style={{ borderRadius: "5px", width: "80px" }}
              newClass={`me-2`}
              onClick={handleSubmit}
            />
            <Button
              btnName={`Close`}
              btnColor={`bg-danger text-white`}
              style={{ borderRadius: "5px", width: "80px" }}
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { createNewCategory, editCategory })(
  CategoryDialog
);
